import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

//import Pages from './pages';
import Index_New from './pages/index_new';
import Products from './pages/product_list';
import Checkout_Cart from './pages/checkout_cart';
import Product_Landing from './pages/product_landing';
//import Step_Two from './pages/step_two';
import Step_Two_CC from './pages/step_two_cc';
import Login from './pages/login';
import Login_New from './pages/login_new';
import Register_New from './pages/register_new';
import ContactUs from './pages/contact_us';
import {InitPayment} from './pages/pay_now';
import {Secure_Redirect} from './pages/secure_redirect';
//import Blogs from './pages/Blogs_Articles';
import Receipt from './pages/receipt';
import Nomination_Form from './pages/nomination_form';

//Sample of Component
import AllComp from './sample_comp/all_component';
import Blogs_Page from './pages/blogs_page';
import Blog_Landing from './pages/blog_landing';
import Read_Now from './pages/read_now.js';

//Login User Pages
import My_Profile from './user_pages/my_profile';

//import Invoice_Receipt from './user_pages/invoice_receipt';
import Invoice_Receipt_CC from './user_pages/invoice_receipt_cc';
import My_Orders from './user_pages/my_orders';
import My_Wishlist from './user_pages/my_wishlist';
import My_Gift_Cards from './user_pages/my_gift_cards';


class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
      navigateURI(pathname){
        //alert(pathname);
      }
  render(){
    return <Router><Switch>
      <Route exact path="/contactus" component={ContactUs}/>
      {/* <Route exact path="/blogs" component={Blogs_Page} /> */}
      <Route exact path="/blog/:name" component={Blog_Landing} />
      <Route exact path="/lectures/:name" component={Blog_Landing} />
      {/* <Route exact path="/treatments/:name" component={Blog_Landing} /> */}
      <Route exact path="/books/" component={Products}/>
      <Route exact path="/products/" component={Products}/>
      <Route exact path="/products/:name" component={Products}/>
      <Route exact path="/shop/" component={Products}/>
      <Route exact path="/shop/:name" component={Products}/>
      <Route exact path="/read_now/:name" component={Read_Now}/>
      
      <Route exact path="/brands/:name" component={Products}/>


      <Route exact path="/product/:name" component={Product_Landing}/>
      <Route exact path="/checkout_cart" component={Checkout_Cart}/>
      <Route exact path="/Login" component={Login_New} />
      <Route exact path="/Register" component={Register_New}/>
      <Route exact path="/steptwo" component={Step_Two_CC}/>
      <Route exact path="/initpayment" component={InitPayment}/>
      <Route exact path="/_redirect/:name" component={Secure_Redirect}/>
      {/* <Route exact path="/invoice_receipt" component={Receipt} /> */}
      <Route exact path="/invoice_receipt" component={Invoice_Receipt_CC} />
      <Route exact path="/my_profile" component={My_Profile}/>
      <Route exact path="/my_orders" component={My_Orders} />
      <Route exact path="/my_invoices" component={My_Orders} />
      <Route exact path="/my_wishlist" component={My_Wishlist}/>
      <Route exact path="/my_gift_cards" component={My_Gift_Cards}/>
    {/* <Route exact path="/*" component={Pages} /> */}
      <Route exact path="samudra_manthan_awards/home" component={Index_New} />
      <Route exact path="samudra_manthan_awards/sponshership" component={Index_New} />
      <Route exact path="samudra_manthan_awards/nomination_process" component={Index_New} />
      <Route exact path="samudra_manthan_awards/committee" component={Index_New} />
      <Route exact path="samudra_manthan_awards/award_categories" component={Index_New} />
      
      <Route exact path="samudra_manthan_awards/role_of_honour" component={Index_New} />
      <Route exact path="samudra_manthan_awards/role_of_honour" component={Index_New} />

      <Route exact path="/samudra_manthan_awards/nomination_form" component={Nomination_Form} />

      <Route exact path="/*" component={Index_New}/>
      
  </Switch></Router>
  }
}
export default App;