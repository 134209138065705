import  $ from 'jquery';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,CallMapMethod_CBack,sortList} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import {Product_Listing,searchProductsLists} from '../sample_comp/product_listing';
import Search_Filters from '../component_version2/search_filters.js';

import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';

import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';
import Testimonials from '../sample_comp/testimonials';
import Article_Listing from '../sample_comp/article_listing';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import Image_Collarge_Grid from '../component_version2/image_collarge_grid';
import Feature_Explore_Comp from '../component_version2/feature_explore_comp';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let categoryParam="";
let headlinks=[];
let _listProducts=[];

let _linkHier="";
let _ser="";
let _rf="";
let _qType=""
let queries;
class Product_List extends React.Component{
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        headlinks=loc.split('/');
        categoryParam= this.props.match.params.name;
        //alert("check category param="+categoryParam);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.ser!==null){
                    _ser=queries.ser;
                  }
                  if(queries.rf!==null){
                    _rf=queries.rf;
                  }
                  if(queries.type!==null){
                    _qType=queries.type;
                  }
          }


      }
    componentDidMount(){
        clsObject=this;
        /*
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"0"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  */
  var mapCat=[];
  if(loc===""){
      loc="home";
  }
  
  //alert(loc);
  //alert(categoryParam);
  var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});

  /* only for product */
  pgMap.push({name:"get_prod",value:"d"});
  
  pgMap.push({name:"p1",value:categoryParam});
  pgMap.push({name:"p2",value:"0"});
  if(_ser!==""){
    pgMap.push({name:"ser",value:_ser});
  }
  if(_rf!=="" && typeof _rf!=="undefined"){
    //alert("i m in alert rel "+_rf);
    pgMap.push({name:"rel",value:_rf});
    pgMap.push({name:"rel_type",value:_qType})
  }
  //mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
  
    }
    callBackpgData(data,msg){
        //alert("testing of2 "+data);
        //$("#pgComp").html(data);
        let listObj=JSON.parse(data);
        //alert("i m in main"+listObj.flt_category);
        //var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
          if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                // alert("i m in condition");
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].img_text_desc_img_right){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_right,"right");
            }
            else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }else if(listObj.access[_dlist].testimonials){
                injCondition=true;
                mainHt+=Testimonials(listObj.access[_dlist].testimonials);
            }else if(listObj.access[_dlist].image_collarge_grid){
                injCondition=true;
                
                mainHt+=Image_Collarge_Grid(listObj.access[_dlist].image_collarge_grid);
            }else if(listObj.access[_dlist].feature_explore){
                injCondition=true;
                mainHt+=ReactDOMServer.renderToString(<Feature_Explore_Comp lsData={listObj.access[_dlist].feature_explore} />);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==2){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                   // mainHt+=Features_Slider();
                    //alert(products);
                    this.callBackProList(JSON.stringify(listObj.product));
                    mainHt+=products;
                }
           }
          
        }

          
            //$("#pgComp").innerHtml=mainHt;
        }
        
          //alert(JSON.stringify(listObj.product));
          //alert("i m in main check"+JSON.stringify(listObj.flt_category));
          this.callBackProList(JSON.stringify(listObj.product),"",listObj.flt_category);
          //alert(mainHt); 
          mainHt+=products;
        
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);

        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
        RespBkMthdMapp(clsObject,"onchangeselect","setSorting");
        RespBkMthdMapp(clsObject,"reset_filters",function(filters){
            clsObject.getProductByRightBar(filters);
        });

        RespBkMthdMapp(clsObject,"hevent_flt_rightbar",function(filters){
          //alert(JSON.stringify(filters));
          clsObject.getProductByRightBar(filters);
        });

        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/specific_listing.js";
        script2.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script2);


          //this.forceUpdateHandler();
      
    }
    setSorting(val){
        // alert(JSON.stringify(_listProducts));
        // return;
        let strSearchPro="";
        if(val==="Price Low to High"){
            let jsData=sortList(_listProducts,"cost",true,true);
            let data=JSON.stringify(jsData);
            strSearchPro=searchProductsLists(data);
        //$("#pro_list_parent").html(strSearchPro);
        }else if(val==="Price High to Low"){
            let jsData=sortList(_listProducts,"cost",false,true);
            let data=JSON.stringify(jsData);
            strSearchPro=searchProductsLists(data);
        //$("#pro_list_parent").html(strSearchPro);
        }else if(val==="Brand"){

        }else if(val==="Name A – Z"){
            let jsData=sortList(_listProducts,"product_name",true,false);
            let data=JSON.stringify(jsData);
            strSearchPro=searchProductsLists(data);
        //$("#pro_list_parent").html(strSearchPro);
        }else if(val==="Name Z – A"){
            let jsData=sortList(_listProducts,"product_name",false,false);
            let data=JSON.stringify(jsData);
            strSearchPro=searchProductsLists(data);
        //$("#pro_list_parent").html(strSearchPro);
        }
        $("#pro_list_parent").html(strSearchPro);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
    }

    getProductByRightBar(filters){
       
        //alert(JSON.stringify(filters));
        let pgMap=[];
  pgMap.push({name:"curl_type",value:"getFilterProducts"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});
  pgMap.push({name:"filters",value:JSON.stringify(filters)});
  pgMap.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  /* only for product */
  pgMap.push({name:"get_prod",value:"d"});
  pgMap.push({name:"p1",value:categoryParam});
  if(filters.categories===""){

    //pgMap.push({name:"p1",value:categoryParam});
  }
  if(_rf!=="" && typeof _rf!=="undefined"){
    //alert("i m in alert rel "+_rf);
    pgMap.push({name:"rel",value:_rf});
    pgMap.push({name:"rel_type",value:_qType})
  }
  
  
  pgMap.push({name:"p2",value:"0"});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getFilterProducts";
  CallMapMethod_CBack(clsObject,pgMap,function(data,msg){
    //return;
    //alert(data+msg);
    if(data!=="0"){
        //let listData=JSON.parse(data);
        _listProducts=JSON.parse(data);
        //alert(_listProducts.length);
        let strSearchPro=searchProductsLists(data);
        $("#pro_list_parent").html(strSearchPro);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
    }
  });

    }

    setCartBk(refId){
        // alert(refId);
         this.setState(state =>({viewData:refId}));
         this.forceUpdate();
         $("#offcanvas-cart").addClass("offcanvas-open");
       }
    callBackProList(data,msg,filters=[]){
    //alert("testing=="+data);
    //alert("Check bb="+JSON.stringify(filters));
      if(data!=="0"){
       //var resPs= Product_Slider(data);
       _listProducts=JSON.parse(data);
       //alert(_listProducts.length);
       //alert(JSON.stringify(filters));
       let sortedFilters=sortList(filters,"category_name",true,false);
       var resPs=Product_Listing(data,true,sortedFilters);
       //alert(resPs);
       products+=resPs;
      //$("#pgComp").append(resPs);
      }
    //   var pgMap=[];
    //   pgMap.push({name:"curl_type",value:"getPgData"});
    //   pgMap.push({name:"res_func",value:"callBackpgData"});
    //   pgMap.push({name:"loc",value:loc});
    //  // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
    //   //mapCat.push({name:"emp_id",value:userObject[0].id});
    //   pgMap["res_func"]="callBackpgData";
    //   pgMap["curl_type"]="getPgData";
    //   CallCMSMapMethod(clsObject,pgMap);
    }
    render(){
        return(<div>
             <Front_Header/>
             
  <div class="parent_tb bg-warning11 bg-light section-heading">
        <div class="line_tb card-body">
            <div class="left_tb">
            <div className="order-icon text-red red col"><i class="icon-arrow-down-circle"></i><div className="order-status"><a className="linkclass" href="../products">All Products</a></div></div>
  
            </div>
            {/* <div class="right_tb">
                {headlinks.map((item,index)=>{
                    if(_linkHier!=""){
                        _linkHier+="/";
                    }
                    _linkHier+=item;
                    return (<a className="linkclass" href={"../"+_linkHier}>{item.replaceAll("_"," ")}/</a>);
                })}
            </div> */}
        </div>
    </div>
                {/* div  */}
              <div id="pgComp">
           </div>
     <Footer/>
        </div>);
    }
}
export default Product_List; 