import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './image_collarge_grid.css';

// component ref from ArticleListing
export function Image_Collarge_Grid(lsData,isBlog=false){
  //alert(JSON.stringify(lsData));
  let gTitle="";
  if(lsData.length>0){
    gTitle=lsData[0]["g_title"];
  }
    if(!isBlog){
        let appPath=appGetUrlPath();
        
        let strHeader="<div  class=\"orange-bg-2 main-blog-area pt-100px pb-100px\">"+
        "<div class=\"container\">"+
        "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar_testi\">"+
                "<h3 class=\"pb-50px title-black title\">"+gTitle+"</h3>"+
            "</div>"+
        "</div>"+
       "</div>"+
        "<div class=\"grid\">";
        let strFooter="</div></div></div>";
        
        let strResp="";
        let urlHr="";
        for(let i=0;i<lsData.length;i++){
          urlHr="javascript:void(0)";
          if(lsData[i]["col7"]!==""){
            urlHr=lsData[i]["col7"];
          }
          if(lsData[i]["col4"].trim().indexOf(".mp4")===-1){
            strResp+="<div class=\"photo thumb hover-image\">"+
            "<a href=\""+appPath+lsData[i]["col4"].trim()+"\" class=\"gallery-item image\">"+
            "<img src=\""+appPath+lsData[i]["col4"].trim()+"\" height=\"500px\" class=\"img-thumbnail\" width=\"800\" height=\"533\" alt=\"Photo\"/>"+
          "</a>"+
          "</div>"
          }else{
            strResp+="<div class=\"photo\">"+
           
            "<video controls type=\"video/mp4\" src=\"" + appPath+lsData[i]["col4"].trim() + "\"/>"+
          
          "</div>"
          }
          

        }
       
       
           return strHeader+ strResp+strFooter;
    }else{
        let appPath=appGetUrlPath();
        let strHeader="<div class=\"main-blog-area pt-10px pb-50px\">"+
        "<div class=\"container1\">"+
        "<div class=\"grid\">";
        let strFooter="</div></div></div>";
        
        let strResp="";
        for(let i=0;i<lsData.length;i++){
          

          strResp+="<div class=\"photo\">"+
          "<img src=\""+appPath+lsData[i]["col4"].trim()+"\" width=\"800\" height=\"533\" alt=\"Photo\">"+
          "<div class=\"clrg_title\" >"+lsData[i]["col1"]+"</div>"+
        "</div>"
        if(i>=4){
          break;
        }
        }
       
       
           return strHeader+ strResp+strFooter;
    }
   
}
export default Image_Collarge_Grid;
