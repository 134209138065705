import './comp_sma_sidebar.css';
import newAppsImg from '../assets/images/appnew.png';
import sma2023Img from '../assets/images/sma_2023.png';
import sma2024 from '../assets/images/SMA2024.png';

export const Comp_Sma_Sidebar=(props)=>{
    return(
        <div class="container-fluid">
        <div id="social-sidebar" class="sticky-sidebar">
            <ul class="list-unstyled">
                <li><div class="floating-icon1 fb"><a target="_blank" href="https://www.facebook.com/BhandarkarShippingNews/"><i class="fa fa-facebook-square"></i></a></div></li>
                <li><div class="floating-icon1"><a target="_blank" href="https://x.com/NEWSONShipping"><i class="fa fa-twitter-square"></i></a></div></li>
                {/* <li><div class="floating-icon1"><a href="#"><i class="fa fa-google-plus-square"></i></a></div></li> */}
                <li><div class="floating-icon1"><a target="_blank" href="https://www.instagram.com/bhandarkarshipping/"><i class="fa fa-instagram"></i></a></div></li>
                <li><div class="floating-icon1"><a targer="_blank" href="https://www.linkedin.com/in/bhandarkar-shipping-news-6aa249125"><i class="fa fa-linkedin-square"></i></a></div></li>
                <li><div class="floating-icon1"><a href="../mobile_apps"><img src={newAppsImg}/></a></div></li>
                <li><div class="floating-icon1"><a href="../samudra_manthan_awards/home"><img src={sma2024}/></a></div></li>
            </ul>
        </div>
    </div>
    );
}
export default Comp_Sma_Sidebar;