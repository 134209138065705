import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

//const appUrl="http://localhost:8086/dhanvantari_admin/";
//const appUrtPath="http://localhost:8086/dhanvantari_admin/upd/";
//const appUrl="https://cms.mbdhwellness.com/";
//const appUrtPath="https://cms.mbdhwellness.com/mbdh/";
const appUrl="https://cms.bhandarkarpublications.com/controller/pgControl.php?pm=";
const appUrtPath="https://cms.bhandarkarpublications.com/bhandarkar_web_upd/";


window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;

export const appGetUrlPath=()=>{
  return appUrtPath;
}
export const appGetMainUrl=()=>{
  return appUrl;
}

export const CallCMSMapMethod=(obCls,frmValues)=>{
   
    var curl_type=frmValues['curl_type'];
 
     //alert("check url == "+curl_type);
     var divRes="";
     var getCallFunc=""
     if(frmValues['res_name'])
     {
       divRes=frmValues['res_name'];
     }
     if(frmValues['res_func'])
     {
       getCallFunc=frmValues['res_func'];
     }
         var ss=frmValues;
         //var xs = "{objs:" + JSON.stringify(ss) + "}";
        //alert(xs);
          $.ajax({
             crossDomain:true,
                 type: "POST",
                 url: appUrl+curl_type,
                 data:ss,
              success: function (data) {
                //alert(data);   
               //ajaxindicatorstop();
                  // return data;
                  if(divRes!="")
                  {
                      if ($("#" + divRes).length!=0)
                   $("#"+divRes).html(data);
                  }
                  if(getCallFunc!="")
                  {
                   var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                   var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                   //  eval(getCallFunc);
                  // alert(checkMsg);
                  return obCls[getCallFunc](checkData,checkMsg);
                     }
                    
                 }
             });
 }
export const getRoleDetails=(roles,uriPath)=>{
  const listFltr = d => d.col2 === uriPath && d.status==='side';
  var topMenusList = roles.filter(listFltr);
  var listFilter;
  for(var i=0;i<topMenusList.length;i++){
    const childFltr = d => d.parent_id === topMenusList[i]["parent_id"] && d.status === 'side';
    listFilter = roles.filter(childFltr);
  }
  return listFilter;
}
export const getAccessRolesNew=(roles)=>{

    var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
    //alert(topMenusList.length);
    for (var i = 0; i < topMenusList.length; i++) {
        const childFltr = d =>d.col6 === 'menu' && d.parent_id === topMenusList[i]["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
        if (childMenuList.length === 0) {
          //alert(topMenusList[i]["col1"]);
          if(topMenusList[i]["col1"]==="E-Consultation"){
            getMenu += " <li>" +
            "<a target=\"blank\" href = \"" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
            "</li>";
          }else{
            getMenu += " <li>" +
                "<a href = \"/" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
                "</li>";
          }
            
        } else {
            getMenu += "<li class=\"dropdown\">" +
            "<a href=\"#\">"+topMenusList[i]["col1"]+" <i class=\"ion-ios-arrow-down\"></i></a>";
               
            getChMenu = "<ul class=\"sub-menu\">";
            for (var ch = 0; ch < childMenuList.length; ch++) {
                const child2Fltr=d => d.parent_id === childMenuList[ch]["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                
                if(child2MenuList.length!=0){
                  getChMenu += "<li class=\"dropdown position-static\"><a href=\"" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "<i class=\"ion-ios-arrow-right\"></i></a>";
                  getChMenu+="<ul class=\"sub-menu sub-menu-2\">";
                }else{
                  getChMenu += "<li><a href=\"/" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "</a></li>";
                }
                for(var k=0;k<child2MenuList.length;k++){
                  getChMenu += "<li><a href=\"/" + child2MenuList[k]["col2"] + "\">" + child2MenuList[k]["col1"] + "</a></li>";
                }
                if(child2MenuList.length!=0){
                  getChMenu+="</ul></li>";
                }
            }
            getChMenu += "</ul>";
            getMenu += getChMenu + "</li>";
        }
  
        // getMenu += topMenusList[i]["col1"];
    }
    return getMenu;
   
  }

  
export const GetImgCertUserCMS_CBack=(obCls,frmNm,curl,callback)=>{
  ajaxindicatorstart("Processed Please Wait..");
  //alert(_cmsAppUrl+curl);
  var options={
    url     : appUrl+curl,
    success : function(data)
    {
      ajaxindicatorstop();
      // alert(data);
      // $("#errLog").html(data);
      // return;
      //var resData=data.documentElement.childNodes[0].nodeValue;
     var resData=data;
      var checkData=$($($.parseHTML(resData)).filter("#resP")).html();
     var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).html();

     //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
     var userMsg="";  
     return callback(checkData,checkMsg,userMsg);
        
 },
    error: function(data) {
      ajaxindicatorstop();
       return callback(0,JSON.stringify(data),"0");
     }
   };
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="2px solid rgb(195 17 17)";
       isValidated=false;
     }
   }
   isValidated=true;
   if(!isValidated){
    ajaxindicatorstop();
     return callback(-10,"Fill Required Fileds","");
   }else{
    // alert(frmNm);
    $(frmNm).ajaxSubmit( options );
   }

 //$(frmNm).ajaxSubmit( options );

}

export function ajaxindicatorstart(text) {
  if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
      jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
  }

  jQuery('#resultLoading').css({
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'z-index': '10000000',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto'
  });

  jQuery('#resultLoading .bg').css({
      'background': '#000000',
      'opacity': '0.7',
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'top': '0',
      'margin-left': '0'
  });

  jQuery('#resultLoading>div:first').css({
      'width': '250px',
      'height': '75px',
      'text-align': 'center',
      'position': 'fixed',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto',
      'font-size': '16px',
      'z-index': '99999999999',
      'color': '#ffffff'

  });

  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeIn(300);
  jQuery('body').css('cursor', 'wait');
}

export function ajaxindicatorstop() {
  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeOut(300);
  jQuery('body').css('cursor', 'default');
}

export default CallCMSMapMethod