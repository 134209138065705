import React from 'react';
import './home_banner.css';
import {sortList} from '../function_lib/common_lib';
import { appGetUrlPath } from '../function_lib/common_cms';
export function Home_Banner2Div(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
  
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
         let rfUrl="javascript:void(0)";
         if(lsData[i]["col7"].trim()!==""){
            rfUrl=lsData[i]["col7"].trim();
         }
         //style=\"background:url("+appPath+lsData[i]["col4"]+") right no-repeat;\"
       strResp+="<div  class=\"hero-slide-item slider-height swiper-slide d-flex\">"+
      "<div  class=\"container align-self-center\">"+
      "<div class=\"row\">"+
          "<div class=\"col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center text-center\">"+
              "<div class=\"hero-slide-content slider-animated-1\">"+
                //   "<span class=\"category\">New Products</span>"+
                  "<h2 class=\"title-1\">"+lsData[i]["col1"]+"</h2>"+
                  "<div>"+lsData[i]["col2"]+"</div>"+
                  "<a href=\"../products\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5\">Shop Now</a>"+
              "</div>"+
          "</div>"+
          "<div class=\"col-xl-6 col-lg-5 col-md-5 col-sm-5 bg-rectangle-animation\">"+
              "<div class=\"hero-slide-image img-bg-shape\">"+
                  "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"banner\" />"+
              "</div>"+
          "</div>"+
      "</div>"+
  "</div>"+ 
      
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export function Home_Banner_WithNews(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
    if(lsData.length>0){
        if(lsData[0]["col14"]!==""){
            //alert(lsData[0]["col14"]);
            lsData=sortList(lsData,"col14",false,true);
            //alert("i min sorting");
        }
    }
  
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero-slider-70 hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div>"+
    
    "<div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
   
"</div>"+
"<div id=\"latest_update\">"+"place test"+"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
         let rfUrl="javascript:void(0)";
         if(lsData[i]["col7"].trim()!==""){
            rfUrl=lsData[i]["col7"].trim();
         }
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       
       "<div class=\"hero-bg-image\">"+
       "<a href=\""+rfUrl+"\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" style=\"position:relative\" alt=\"banner\"/>"+
        "</a>"+
       "</div>"+
       "<div class=\"container align-self-center overlay\">"+
                        "<div class=\"row justify-content-center\">"+
                            "<div class=\"col-md-8 offset-2 align-self-center m-auto\">"+
                                "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
                                    "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
                                    "<h2 class=\"title-1\">"+lsData[i]["col2"]+"</h2>"+
                                    "<p class=\"w-100\">"+lsData[i]["col3"]+"</p>"+
                                    // "<a href=\"#\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5\">Shop Now12</a>"+
                                "</div>"+
                            "</div>"+
                        "</div>"+
                    "</div>"+
    //    "<div class=\"container1\" style=\"background-color:"+styleRBg+";width:100%\">"+
    //        "<div class=\"row\">"+
    //            "<div class=\"col-md-5 align-self-center txt_on_slider\">"+
    //                "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
    //                    "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
    //                    "<p class=\"w-100\">"+lsData[i]["col2"]+"</p>"+
    //                     "<a href=\"../products\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5\">Shop Now</a>"+
    //                "</div>"+
    //            "</div>"+
    //        "</div>"+
    //    "</div>"+
      
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export function Home_Banner(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
  
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
         let rfUrl="javascript:void(0)";
         if(lsData[i]["col7"].trim()!==""){
            rfUrl=lsData[i]["col7"].trim();
         }
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       
       "<div class=\"hero-bg-image\">"+
       "<a href=\""+rfUrl+"\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"banner\"/>"+
        "</a>"+
       "</div>"+
    //    "<div class=\"container1\" style=\"background-color:"+styleRBg+";width:100%\">"+
    //        "<div class=\"row\">"+
    //            "<div class=\"col-md-5 align-self-center txt_on_slider\">"+
    //                "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
    //                    "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
    //                    "<p class=\"w-100\">"+lsData[i]["col2"]+"</p>"+
    //                     "<a href=\"../products\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5\">Shop Now</a>"+
    //                "</div>"+
    //            "</div>"+
    //        "</div>"+
    //    "</div>"+
      
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export default Home_Banner;