import React,{useEffect} from 'react';
import {GetImgCertUser_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import {GetImgCertUserCMS_CBack} from '../function_lib/common_cms';
import './comp_nomination_form.css';
export const Comp_Nomination_Form=(props)=>{

    const submitNominationRequest=()=>{
        GetImgCertUserCMS_CBack(this,"#frmNominationForm","addNewNominationReq",function(checkData,checkMsg,userMsg){
            if(checkData===-10){
                alert("Kindly fill required fields");
                return;
              }else {
                alert("Nomination request submitted successfully");
                //Custom_Alert("Successfully Added",checkMsg,false);
                //alert(checkMsg);
                //$('#divCmsAddNew').modal('hide');
                window.location.reload();
              }
            });
        
    }
 

    useEffect(()=>{
      const  handleRadioChange=(event)=> {
            const selectedOptionId = event.target.id;
            const subOptions = document.querySelectorAll('.sub-options');
            
            subOptions.forEach(option => {
                if (option.id === 'subOptions' + selectedOptionId.replace('option', '')) {
                    option.classList.add('show');
                } else {
                    option.classList.remove('show');
                }
            });
        }

        document.querySelectorAll('input[name="col10"]').forEach(radio => {
            radio.addEventListener('change', handleRadioChange);
        });


    },[]);
    return(<div class="awardformdiv container ">
        <div class="row">
            <div class="col-md-8 col-sm-12">
                <form id="frmNominationForm" name="frmNominationForm"  enctype="multipart/form-data" method="POST" action="#">
                    <div class="form-group col-md-12 col-sm-12">
                         
                        <label class="form-check-label" for="inlineCheckbox1">
                            <input type="checkbox" name="col15" /> I am interested in sponsorship and/or nomination opportunities</label>
                    </div>
                    <div class="row111 row pt-2 col-md-12 col-sm-12 ">
                            <div class="form-group row col-md-5 col-sm-5 mx-1">
                                <h6>Company Name</h6>
                                <input type="text" class="form-control required" id="col1" name="col1" placeholder="Enter Company Name"/>
                            </div>
                            <div class="form-group row col-md-5 mx-1">
                                <h6>Your Address</h6>
                                  <input type="text" class="form-control required" id="col2" name="col2" placeholder="Enter Company Name"/>
                            </div>  
                            
                            <div class="form-group row col-md-5 col-sm-5 mx-1">
                                <h6>Business Activity</h6>
                                <select class="form-control required" id="col3" name="col3">
                                    <option value="0">Select</option>
                                  <option value="ShipOwnerOperator">Ship Owner / Operator</option>
                                  <option value="ServiceProvider">Service Provider</option>
                                  <option value="Shipbrokers">Shipbrokers</option>
                                  <option value="Lawfirms">Law Firms</option>
                                  <option value="Consultants">Consultants</option>
                                  <option value="Others">Others</option>
                                </select>
                            </div>  
                            <div class="form-group row col-md-5 mx-1">
                                <h6>Contact Person Name</h6>
                                  <input type="text" class="form-control required" id="col4" name="col4" placeholder="Enter Contact Person Name"/>
                            </div>
                            <div class="form-group row col-md-5 col-sm-5 mx-1" >
                                <h6>Job Title</h6>
                                  <input type="text" class="form-control required" id="col5" name="col5" placeholder="Enter Your Job Title"/>
                            </div>
                            <div class="form-group row col-md-5 mx-1 ">
                                <h6>Emial ID</h6>
                                  <input type="email" class="form-control required" id="col6" name="col6" placeholder="Enter Your Email Id"/>
                            </div> 
                            <div class="form-group row col-md-5 col-sm-5 mx-1">
                                <h6>Telephone No.</h6>
                                  <input type="text" class="form-control required" id="col7" name="col7" placeholder="Enter Your Telephone No."/>
                            </div>
                            <div class="form-group row col-md-5 mx-1">
                                <h6>Fax No.</h6>
                                  <input type="text" class="form-control " id="col8" name="col8" placeholder="Enter Your Fax No."/>
                            </div> 
                            <div class="form-group row col-md-10 col-sm-10 mx-1">
                                <h6>Specify Achievements supporting your Nomination.</h6>
                                  <input type="text" class="form-control required" id="col9" name="col9" placeholder="Enter Your Achievements"/>
                            </div> 
                             {/* Award Category   */}
                             <div class="col-md-10 col-sm-10 mx-1">
                                <h6 class="mx-1">
                                    Please Select below Award Category & Sub-Category click "Download File" which will display criteria for nomination & click "Upload File" with details of criteria
                                </h6>
                             </div>
                            
                          {/*  Main Category   */}
                        <div class="container mt-2">
                            <div class="form-group radio-group">
                               <div class="col-md-12 col-sm-12">
                                 {/* <!-- 1 --> */}
                                 <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option1" value="DIAMOND (SHIPPING COMPANY OF THE YEAR)"/>
                                    <label class="form-check-label" for="option1">
                                      DIAMOND (SHIPPING COMPANY OF THE YEAR)
                                    </label>
                                    <div class="sub-options row" id="subOptions1">
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="SHIPPING COMPANY OF THE YEAR - INDIAN" value="SHIPPING COMPANY OF THE YEAR - INDIAN"/>
                                            <label class="form-check-label" for="SHIPPING COMPANY OF THE YEAR - INDIAN">
                                              SHIPPING COMPANY OF THE YEAR - INDIAN <a class="linkclass" href="/FORMS/shipping-company-of-the-year-indian.docx" download>(Download form)</a>

                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="SHIPPING COMPANY OF THE YEAR - INTERNATIONAL" value="SHIPPING COMPANY OF THE YEAR - INTERNATIONAL"/>
                                            <label class="form-check-label" for="SHIPPING COMPANY OF THE YEAR - INTERNATIONAL">
                                              SHIPPING COMPANY OF THE YEAR - INTERNATIONAL <a class="linkclass" href="/FORMS/shipping-company-of-the-year-international.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- 1 --> */}
                               </div>
                               <div class="col-md-12 col-sm-12">
                                 {/* <!-- 2 --> */}
                                <div class="form-check ">
                                    <input class="form-check-input" type="radio" name="col10" id="option2" value="RUBY (PORTS)"/>
                                    <label class="form-check-label" for="option2">
                                      RUBY (PORTS)
                                    </label>
                                    <div class="sub-options row" id="subOptions2">
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption2-1" value="MAJOR PORT OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption2-1">
                                              MAJOR PORT OF THE YEAR <a class="linkclass" href="/FORMS/major-port-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption2-2" value="NON MAJOR PORT OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption2-2">
                                              NON MAJOR PORT OF THE YEAR<a class="linkclass" href="/FORMS/non-major-port-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption2-3" value="SHIPPING TERMINAL OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption2-3">
                                              SHIPPING TERMINAL OF THE YEAR<a class="linkclass" href="/FORMS/shipping-terminal-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                            
                                        </div>  
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 2 -->
                                 <!-- 3 --> */}
                                 <div class="col-md-12 col-sm-12">  
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option3" value="EMERALD (TECHNOLOGY PROVIDER)"/>
                                    <label class="form-check-label" for="option3">
                                      EMERALD (TECHNOLOGY PROVIDER)
                                    </label>
                                    <div class="sub-options" id="subOptions3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption3-1" value="TECHNOLOGY PROVIDER OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption3-1">
                                              TECHNOLOGY PROVIDER OF THE YEAR<a class="linkclass" href="/FORMS/technology-provider-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                   
                                </div>
                            </div> 
                                {/* <!-- 3 -->
                                <!-- 4 --> */}
                                <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option4" value="PEARL (TRANSPORT OPERATOR)"/>
                                    <label class="form-check-label" for="option4">
                                        PEARL (TRANSPORT OPERATOR)
                                    </label>
                                    <div class="sub-options row" id="subOptions4">
                                        <div class="form-check col-12 ">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption4-1" value="RAIL TRANSPORT OPERATOR OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption4-1">
                                                RAIL TRANSPORT OPERATOR OF THE YEAR<a class="linkclass" href="/FORMS/rail-transport-operator-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption4-2" value="ROAD TRANSPORT OPERATOR OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption4-2">
                                                ROAD TRANSPORT OPERATOR OF THE YEAR <a class="linkclass" href="/FORMS/road-transport-operator-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 4 -->
                                 <!-- 5 --> */}
                                <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                  <input class="form-check-input" type="radio" name="col10" id="option5" value="TOPAZ (CONTAINERIZED LIQUID & CARGO OPERATORS)"/>
                                  <label class="form-check-label" for="option5">
                                    TOPAZ (CONTAINERIZED LIQUID & CARGO OPERATORS)
                                  </label>
                                  <div class="sub-options row" id="subOptions5">
                                      <div class="form-check  col-12">
                                          <input class="form-check-input" type="radio" name="col11" id="subOption5-1" value="ISO TANK OPERATOR OF THE YEAR"/>
                                          <label class="form-check-label" for="subOption5-1">
                                            ISO TANK OPERATOR OF THE YEAR<a class="linkclass" href="#" download>(Download form)</a>
                                          </label>
                                      </div>
                                      <div class="form-check  col-12">
                                          <input class="form-check-input" type="radio" name="col11" id="subOption5-2" value="ODC OPERATOR OF THE YEAR"/>
                                          <label class="form-check-label" for="subOption5-2">
                                            ODC OPERATOR OF THE YEAR<a class="linkclass" href="/FORMS/odc-operator-of-the-year.docx" download>(Download form)</a>
                                          </label>
                                      </div>
                                      <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                    </div>
                                  </div>
                              </div>
                            </div>
                              {/* <!-- 5 -->
                                <!-- 6 --> */}
                                <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option6" value="SAPPHIRE (PLACEMENT-RETENTION-TRAINING)"/>
                                    <label class="form-check-label" for="option6">
                                        SAPPHIRE (PLACEMENT-RETENTION-TRAINING)
                                    </label>
                                    <div class="sub-options row" id="subOptions6">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption6-1" value=" TRAINING INSTITUTE OF THE YEAR (PRE SEA)"/>
                                            <label class="form-check-label" for="subOption6-1">
                                                TRAINING INSTITUTE OF THE YEAR (PRE SEA)<a class="linkclass" href="/FORMS/training-institute-of-the-year-(pre-sea).docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption6-2" value="STRAINING INSTITUTE OF THE YEAR (POST SEA)"/>
                                            <label class="form-check-label" for="subOption6-2">
                                                TRAINING INSTITUTE OF THE YEAR (POST SEA)<a class="linkclass" href="/FORMS/training-institute-of-the-year-(post-sea).docx" download>(Download form)</a> 
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption6-3" value="SHIP-OWNER MANNING COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption6-3">
                                                SHIP-OWNER MANNING COMPANY OF THE YEAR<a class="linkclass" href="/FORMS/ship-owner-manning-company-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption6-4" value="MANNING COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption6-4">
                                                MANNING COMPANY OF THE YEAR<a class="linkclass" href="" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 6 -->
                                 <!-- 7 --> */}
                                 <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option7" value=" OPAL (PROJECT CARGO COMPANY)"/>
                                    <label class="form-check-label" for="option7">
                                        OPAL (PROJECT CARGO COMPANY)
                                    </label>
                                    <div class="sub-options row" id="subOptions7">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption7-1" value="PROJECT CARGO COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption7-1">
                                            PROJECT CARGO COMPANY OF THE YEAR <a class="linkclass" href="/FORMS/project-cargo-company-of-the-year.docx" download>(Download form)</a> 
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 7 -->
                                  <!-- 8 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option8" value="AQUAMARINE (DEALS)"/>
                                    <label class="form-check-label" for="option8">
                                        AQUAMARINE (DEALS)
                                    </label>
                                    <div class="sub-options" id="subOptions8">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption8-1" value="DEAL OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption8-1">
                                                DEAL OF THE YEAR<a class="linkclass" href="/FORMS/deal-of-the-year.docx" download>(Download form)</a>  
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 8 -->
                                  <!-- 9 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option9" value="ZIRCON (INSURER)"/>
                                    <label class="form-check-label" for="option9">
                                        ZIRCON (INSURER)
                                    </label>
                                    <div class="sub-options row" id="subOptions9">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption9-1" value="INSURER OF THE YEAR ( GENERAL INSURANCE )"/>
                                            <label class="form-check-label" for="subOption9-1">
                                                INSURER OF THE YEAR ( GENERAL INSURANCE ) <a class="linkclass" href="" download>(Download form)</a> 
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption9-2" value="SURVEYING COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption9-2">
                                            SURVEYING COMPANY OF THE YEAR  
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 9 -->
                                  <!-- 10--> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option10" value="PERIDOT (MULTIMODAL LOGISTIC PARK)"/>
                                    <label class="form-check-label" for="option10">
                                        PERIDOT (MULTIMODAL LOGISTIC PARK)
                                    </label>
                                    <div class="sub-options row" id="subOptions10">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-1" value="MULTI MODAL LOGISTICS PARK OF THE YEAR (CFS)"/>
                                            <label class="form-check-label" for="subOption10-1">
                                                MULTI MODAL LOGISTICS PARK OF THE YEAR (CFS)<a class="linkclass" href="/FORMS/multi-modal-logistics-park-of-the-year-cfs.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-2" value="CFS OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption10-2">
                                                CFS OF THE YEAR <a href="/FORMS/cfs-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-3" value="CFS OF THE YEAR (SPECIFIC)"/>
                                            <label class="form-check-label" for="subOption10-3">
                                                CFS OF THE YEAR (SPECIFIC)<a class="linkclass" href="/FORMS/cfs-of-the-year-specific-new.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-4" value="CFS OF THE YEAR (LCL)"/>
                                            <label class="form-check-label" for="subOption10-4">
                                                CFS OF THE YEAR (LCL)<a class="linkclass" href="/FORMS/cfs-of-the-year-lcl.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-5" value="MULTI MODAL LOGISTICS PARK OF THE YEAR (ICD)"/>
                                            <label class="form-check-label" for="subOption10-5">
                                                MULTI MODAL LOGISTICS PARK OF THE YEAR (ICD)<a class="linkclass" href="/FORMS/multi-modal-logistics-park-of-the-year-icd.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-6" value="WAREHOUSE COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption10-6">
                                                WAREHOUSE COMPANY OF THE YEAR<a class="linkclass" href="/FORMS/warehouse-company-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption10-7" value="CONSOLIDATOR OF THE YEAR (LCL)"/>
                                            <label class="form-check-label" for="subOption10-7">
                                                CONSOLIDATOR OF THE YEAR (LCL)<a class="linkclass" href="" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 10 -->
                                  <!-- 11 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option11" value="CITRINE (FREIGHT FORWARDER AND CONSULTANT OF THE YEAR)"/>
                                    <label class="form-check-label" for="option11">
                                        CITRINE (FREIGHT FORWARDER AND CONSULTANT OF THE YEAR)
                                    </label>
                                    <div class="sub-options row" id="subOptions11">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption11-1" value="FREIGHT FORWARDER OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption11-1">
                                                FREIGHT FORWARDER OF THE YEAR<a class="linkclass" href="/FORMS/freight-forwarder-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption11-2" value="FREIGHT CONSULTANT OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption11-2">
                                                FREIGHT CONSULTANT OF THE YEAR<a class="linkclass" href="/FORMS/freight-broker-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption11-3" value="SLOGISTICS COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption11-3">
                                                LOGISTICS COMPANY OF THE YEAR<a class="linkclass" href="/FORMS/logistics-company-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 11 -->
                                  <!-- 12 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option12" value="GARNET (SHIPPING BROKER)"/>
                                    <label class="form-check-label" for="option12">
                                        GARNET (SHIPPING BROKER)
                                    </label>
                                    <div class="sub-options row" id="subOptions12">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption12-1" value="SHIPPING BROKER OF THE YEAR (INDIAN)"/>
                                            <label class="form-check-label" for="subOption12-1">
                                                SHIPPING BROKER OF THE YEAR (INDIAN)<a class="linkclass" href="" download>(Download form)</a> 
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption12-2" value="SHIPPING BROKER OF THE YEAR (INTERNATIONAL)"/>
                                            <label class="form-check-label" for="subOption12-2">
                                                SHIPPING BROKER OF THE YEAR (INTERNATIONAL)  <a class="linkclass" href="" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 12 -->
                                  <!--13 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option13" value="TURQUOISE (MARINE WELFARE, SAFETY & SECURITY, ENVIRONMENT & PEOPLE CARE)"/>
                                    <label class="form-check-label" for="option13">
                                        TURQUOISE (MARINE WELFARE, SAFETY & SECURITY, ENVIRONMENT & PEOPLE CARE)
                                    </label>
                                    <div class="sub-options" id="subOptions13">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption13-1" value="SHE COMPANY OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption13-1">
                                                SHE COMPANY OF THE YEAR<a class="linkclass" href="" download>(Download form)</a>  
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 13 -->
                                  <!-- 14 --> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option14" value="AMETHYST"/>
                                    <label class="form-check-label" for="option14">
                                        AMETHYST
                                    </label>
                                    <div class="sub-options" id="subOptions14">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption14-1" value="NVOCC OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption14-1">
                                                NVOCC OF THE YEAR<a class="linkclass" href="/FORMS/amethyst.docx" download>(Download form)</a>  
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* <!-- 14 -->
                                  <!-- 15--> */}
                                  <div class="col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="col10" id="option15" value="Special Category Awards"/>
                                    <label class="form-check-label" for="option15">
                                        Special Category Awards
                                    </label>
                                    <div class="sub-options row" id="subOptions15">
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-1" value="MENTOR OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption15-1">
                                                MENTOR OF THE YEAR<a class="linkclass" href="/FORMS/mentor-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check  col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-2" value="LIFE TIME ACHIEVEMENT AWARD"/>
                                            <label class="form-check-label" for="subOption15-2">
                                                LIFE TIME ACHIEVEMENT AWARD<a class="linkclass" href="/FORMS/life-time-achievement-award.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-3" value="Woman Achiever of the Year"/>
                                            <label class="form-check-label" for="subOption15-3">
                                                Woman Achiever of the Year<a class="linkclass" href="/FORMS/empowered-woman-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-4" value="Late Prof. K. R. Bhandarkar Award"/>
                                            <label class="form-check-label" for="subOption15-4">
                                                Late Prof. K. R. Bhandarkar Award<a class="linkclass" href="/FORMS/late-prof-k-r-bhandarkar-award.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-5" value="SHIP / LOGISTICS PROFESSIONAL OF THE YEAR"/>
                                            <label class="form-check-label" for="subOption15-5">
                                                SHIP / LOGISTICS PROFESSIONAL OF THE YEAR<a class="linkclass" href="/FORMS/ship-logistics-professional-of-the-year.docx" download>(Download form)</a>
                                            </label>
                                        </div>
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="radio" name="col11" id="subOption15-5" value="Anna Malhotra award"/>
                                            <label class="form-check-label" for="subOption15-5">
                                            Anna Malhotra award
                                            {/* <a class="linkclass" href="/FORMS/ship-logistics-professional-of-the-year.docx" download>(Download form)</a> */}
                                            </label>
                                        </div>
                                        <div class="inputdiv col-12">
                                        <button type="button" class="_brFile form-control" setTo="nomination_fl_type" name="topaz">
                                            {/* <input type="file" id="topaz"/> */}
                                            Choose file
                                            </button>  
                                        </div>
                                    </div>
                                </div>
                                <input type="file" class="updfileNameDisplay" id="nomination_fl_type" name="nomination_fl_type" style={{"display":"none"}} />
                              </div>
                                {/* <!-- 15 --> */}
                                <div class="col-md-12 col-sm-12 mt-2"><button type="button" onClick={()=>submitNominationRequest()} class="btn btn-primary">Send Message</button></div>  
                               
                            </div>
                        </div>
                        
                    </div>  
                </form>
            </div>
            <div class="col-md-4 col-sm-12">
              <b>  Some other ways of registration:</b> <br/>
               <b># E-mail your contact details:</b> <br/>
               samudramanthan@bhandarkarpublications.com <br/>
               samudramanthanfoundation@gmail.com <br/>
                <b># Courier this form to:</b> <br/>
               10, Hammersmith Industrial Premises, 
               Sitaladevi Temple Road, Mahim, Mumbai 400 016. 
               Tel: +91-9619418123 <br/>

               <br/>
               <b>Vital details of Samudra Manthan Awards:</b>

                <b>Enquiries:</b>
Sponsorship Opportunities: <br/>
Bharati on tel:+91 93222 40647 <br/>
Teesha: 91 9619418123 <br/>
email: egurukul@bhandarkarpublications.com, <br/>
bhandarkarshippng@gmail.com <br/> <br/>
- It may be necessary for reasons beyond the control of the organisers to alter content, timing and venue. In the unlikely event of the function being cancelled or curtailed due to any reasons beyond the control of Bhandarkar Publications, or it is necessary or advisable to relocate or change the date and / or location of the event, neither Bhandarkar Publications, nor its employees will be held liable.
<br/> <br/>
- Organisors reserve the right to reject or cancel award nominations.
<br/> <br/>
- Organisors reserve the right to restrict or decline entry to uninvited guests at the awards functions.
<br/> <br/>
- Bhandarkar Publications disclaims all liability for any tort or damage arising out of any statement or opinion made in any paper or presentation by any guest author or speaker.
<br/> <br/>
- Bhandarkar Publications Shipping Events will keep you informed about relevant services.
            </div>
        </div>
       
</div>
);
}

export default Comp_Nomination_Form;