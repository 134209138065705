import  $ from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod} from '../function_lib/common_cms';
import {RespBkMthdMapp,setKeyUpMapp,CallMapMethod,getMainUrl} from '../function_lib/common_lib';
import {CityList,tmpAutoList} from '../function_lib/city_india';
import './front_header.css';
import logoImg from '../assets/images/logo/logo.png';

let listRoles;
let loginUser;
let userObject;

let logInObj;
let userOpt;
let clsObject;
let personName;



let _topTitle="";
let _topKeywords="";
let _topDescription="";
let loc="";
let _mainUrl="";
let uri="../login";
class Front_Header extends React.Component{
    logout(rf){
        localStorage.clear();
        window.location.href="../";
      }
    constructor(props){
       super(props);
       loc=this.props.loc;
       _mainUrl=getMainUrl();
       uri=_mainUrl+"login";
       if(localStorage.getItem("userDS")!=null){
        logInObj=JSON.parse(localStorage.getItem("userDS"));
        personName=logInObj[0]["first_name"];
        uri="../my_orders";
        uri=_mainUrl+"my_orders";
        if(personName.trim()===""){
           personName="My Account";
        }
      }
    }

    setMetaTags(listMeta){
        // alert(listMeta.length);
    //  alert(loc);
         for(var m=0;m<listMeta.length;m++){
             if(typeof loc==='undefined'|| loc===""){
                 loc="home";
             }
            // alert(listMeta[m]["col2"]+"=="+loc);
            if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase() || listMeta[m]["col2"].toUpperCase()==="../"+loc.toUpperCase()){
                //alert(listMeta[m]["col2"]);
                _topTitle=listMeta[m]["col3"];
                _topKeywords=listMeta[m]["col4"];
                _topDescription=listMeta[m]["col5"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           
         }
         
     }


    gb_search(ref,e){
        //alert("i m in kyups");
        
        if(e.keyCode===13){
           window.location.href="../products?ser="+ref;
        }else{

            if(ref!==""){
                var map=[];
                map.push({name:"curl_type",value:"getSearchAuto"});
            map.push({name:"res_func",value:"callBackNg"});
            map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
            map.push({name:"loc_ser",value:ref})
            map["res_func"]="callBackNg";
            map["curl_type"]="getSearchAuto";
            CallCMSMapMethod(this,map);
               }else{
                //$("#gb_item_search").html(""); 
               }
        }
       

     }
     callBackNg(data,msg){
        //alert(data);
        //return;
        
        if(data.trim()!=="0"){
            var listVal= JSON.parse(data);
            $('#txtSearchHead').autocomplete('option', 'source', Object.values(listVal));
            $('#txtSearchHead_sm').autocomplete('option', 'source', Object.values(listVal));
        }
    }
    setAutoComplete(){
        
  setKeyUpMapp(this,"gb_search");
  var locCityList=tmpAutoList();
 
  $("#txtSearchHead").autocomplete({
   source:locCityList,
   select:function(event,ui){
    event.preventDefault();
    //alert(ui.item.menu);
    if(ui.item.menu.trim().toUpperCase()==="BLOGS"){
        window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
    }else if(ui.item.menu.trim().toUpperCase()==="PRODUCT"){
       // alert("i m in location");
     let proName=ui.item.label.replace(/\s/g, '_');
     proName=proName.replace(/\//g,'-');
     window.location.href="../product/"+proName+"?rf="+ui.item.e_id;
    }
},focus: function (event, ui) {
 event.preventDefault();
 
 //$(this).autocomplete('search', $("#txtSearchHead").val());
}
}).data("ui-autocomplete")._renderItem = function (ul, item) {
 var newText = String(item.value).replace(
         new RegExp(this.term, "gi"),
         "<span class='ui-state-highlight'>$&</span>");
     //$("#ui-id-1").style
 return $("<li></li>")
     .data("item.autocomplete", item)
     .append("<div>" + newText + "</div>")
     .appendTo(ul);
};

$("#txtSearchHead_sm").autocomplete({
    source:locCityList,
    select:function(event,ui){
     event.preventDefault();
     //alert(ui.item.menu);
     if(ui.item.menu.trim().toUpperCase()==="BLOGS"){
         window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
     }else if(ui.item.menu.trim().toUpperCase()==="PRODUCT"){
      let proName=ui.item.label.replace(/\s/g, '_');
      proName=proName.replace(/\//g,'-');
      window.location.href="../product/"+proName+"?rf="+ui.item.e_id;
     }
 },focus: function (event, ui) {
  event.preventDefault();
  //$(this).autocomplete('search', $("#txtSearchHead").val());
 }
 }).data("ui-autocomplete")._renderItem = function (ul, item) {
  var newText = String(item.value).replace(
          new RegExp(this.term, "gi"),
          "<span class='ui-state-highlight'>$&</span>");
      //$("#ui-id-1").style
  return $("<li></li>")
      .data("item.autocomplete", item)
      .append("<div>" + newText + "</div>")
      .appendTo(ul);
 };
 
    }
    componentDidMount(){
        clsObject=this;
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getAccessToken"});
        mapCat.push({name:"res_func",value:"callBackAccess"});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackAccess";
        mapCat["curl_type"]="getAccessToken";
        CallCMSMapMethod(this,mapCat);
        // userOpt="<button class=\"header-action-btn\">"+
        // "My Orders</button>";
        userOpt="<button class=\"dropdown-toggle header-action-btn\" title=\"login\" data-bs-toggle=\"dropdown\">"+
        "<i class=\"icon-user white1\"></i></button>"+
                                        "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                            //"<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                            //"<li><a class=\"dropdown-item\" href=\"#\">Checkout</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\""+_mainUrl+"login\">Sign in</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\""+_mainUrl+"register\">Register</a></li>"+
                                        "</ul>";
            if(logInObj)
            {
                
                         userOpt= "<button class=\"dropdown-toggle pr-0 border-0 header-action-btn\" data-bs-toggle=\"dropdown\">"+
                                        "<a class=\"white11\" href=\"javascript:void(0)\">"+personName+"<i class=\"ion-ios-arrow-down\"></i>"+"</a>"+"</button>"+
                                                                            "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                                                                "<li><a class=\"dropdown-item\" href=\""+_mainUrl+"my_profile\">My Profile</a></li>"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"./my_profile\">My account</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\""+_mainUrl+"my_orders\">My Orders</a></li>"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                                                                "<li><a class=\"dropdown-item _setbackparam\" param1=\"1\"  href=\"javascript:void(0)\">Logout</a></li>"+
                                                                            "</ul>";   
                                 
                                        }
  //alert(userOpt);
 this.setAutoComplete()


  $("#user_opt_desk").html(userOpt);
  $("#user_opt_mob").html(userOpt);
  RespBkMthdMapp(clsObject,"_setbackparam","logout")

    }
    callBackAccess(data,msg){
       
        if(data!=="0"){
            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            //alert(JSON.stringify(listRoles));
            this.setMetaTags(listRoles);
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm);
          $("#mob_menu").html(listHtm);
        }
    }
    render(){
        return(<div><div className="header section header-bottom">
            {/* top header commented */}
        {/* <div className="header-top section-fluid bg-black">
            <div className="container">
                <div className="row row-cols-lg-2 align-items-center">
                    
                    <div className="col text-center text-lg-left">
                        <div className="header-top-massege">
                            <p>Welcome to Fast RC</p>
                        </div>
                    </div>
                    
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown"> Language : (ENG) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">English</a></li>
                               </ul>
                            </div>
                           
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle pr-0 border-0" data-bs-toggle="dropdown">Currency : (USD) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">USD $</a></li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div> */}
        {/* end of top header */}
        
        <div className="green-dark11 header-bottom11 d-none d-lg-block">
            <div className="container position-relative">
                <div className="d-flex justify-content-center pb-2">
                   
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
                    <div className="p2 custom-search input-group">
    <div className="input-group-text p-0">
        <select className="form-select form-select-lg shadow-none border-0">
            <option value="0">All</option>
            {/* <option value="2">Parts</option>
            <option value="2">Custom Parts</option> */}
        </select>
    </div>
    <input type="text" autoComplete='off' id="txtSearchHead" name="txtSearchHead" className="form-control _setSearchKey" placeholder="Search Here" />
    <button className="input-group-text shadow-none px-4 btn-warning">
    <i className="icon-magnifier"></i>
    </button>
</div>
                    
                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <div className="input-group">
                                        <input className="form-control" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </div>
                               
                                </div>
                            </div>
                            <a href={uri} className="white1">My Orders</a>
                            {/* <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                            </a> */}
                            <div id="user_opt_desk" className="header-bottom-set dropdown">
                               
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart white1"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                 {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        
        <div className="green-dark11 pb11-1  border-box-2 d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center pb-2">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img className="logoimg" src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center" style={{"justify-content":"flex-end"}}>
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn white11"><i
                                        className="icon-magnifier white11"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control _setSearchKey" id="txtSearchHead_sm" name="txtSearchHead_sm" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                          
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart white1"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu white11"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none header-bottom11 d-lg-block sticky-nav">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                        <a href="#offcanvas-mobile-menu" style={{"line-height":"45px","padding-right":"10px"}} className="header-action-btn white header-action-btn-menu offcanvas-toggle">
                                <i className="icon-menu"></i>
                            </a>
                            <ul id="menu_opt">
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }
}
export default Front_Header;