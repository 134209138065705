import React from 'react';
import './search_filters.css';

const Search_Filters=(props)=>{
   
    //alert(JSON.stringify(props));
    return (
    // <section class="start-left-image-right-text">
    // <div class="container">
        // <div class="row">
            <aside class="col-lg-3 filter_bar" id="sidebar_fixed">
                <a href="javascript:void(0)" class="open_filters"><div class="layer"></div></a>
                {/* <!-- Mobile menu overlay mask --> */}
                <div class="filter_col">
                <a class="reset_filters" href="javascript:void(0)"><span class="badges"><span class="new">Reset Filters</span></span></a>
                </div>
                <div class="filter_col">
                    <div class="inner_bt"><a href="#" class="open_filters"><i class="ion-android-close"></i></a></div>
                    
                    {/* <div class="filter_type version_2">
                        <h4><a href="#filter_1" data-toggle="collapse" class="opened">Price</a></h4>
                        <div class="collapse show" id="filter_1">
                            <ul>
                                <li>
                                    <label class="container_check">₹0 — ₹500<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="0,500"  />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹500 — ₹1000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="501,1000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹1000 — ₹1500<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="1001,1500" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹1500 — ₹2000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="1501,2000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹2000<b>+</b>  <small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="2001,-11" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    
                    <div class="filter_type version_2">
                        <h4><a href="#filter_4" data-toggle="collapse" class="opened">Categories</a></h4>
                        <div class="collapse show" id="filter_4" >
                            {/* <div class="custom-search-input">
                                <input type="text" placeholder="Search" />
                                <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div> */}
                            <ul>
                                {props.filters!=="undefined"?props.filters.map((item,index)=>{
                                  if(item.top_code!=="brands" && item.category_code!=="NEW ARRIVALS"
                                  && item.category_code!=="pages" && item.category_code!=="BESTPRO1" 
                                  && item.category_code!=="oz2" && item.category_code!=="fpro" && item.category_code!=="brands"){
                                    return(<li>
                                        <label  class="container_radio">{item.category_name} <small></small>
                                            <input  type="radio" class="hevent_flt_rightbar hcls_flt_cate" name="flt_category" value={item.category_code} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                  }
                                    

                                })
                                :''}
                                
                            </ul>
                        </div>
                        {/* <!-- /filter_type --> */}
                    </div>
                    {/* <!-- /filter_type --> */}
                    {/* <div class="filter_type version_2">
                        <h4><a href="#filter_2" data-toggle="collapse" class="opened">Color</a></h4>
                        <div class="collapse show" id="filter_2">
                            <ul>
                                <li>
                                    <select class="form-control hevent_flt_rightbar_nn hcls_flt_color">
                                        <option value="0">Color Options</option>
                                        <option value="RED">RED</option>
                                        <option value="WHITE">WHITE</option>
                                        <option value="YELLOW">YELLOW</option>
                                    </select>
                                </li>
                                
                            </ul>
                        </div>
                    </div> */}
                    {/* <!-- /filter_type --> */}
                    <div class="filter_type version_2">
                        <h4><a href="#filter_3" data-toggle="collapse" class="closed">Brands</a></h4>
                        <div class="collapse" id="filter_3">
                            <ul>
                                {props.filters!=="undefined"?props.filters.map((item,index)=>{
                                    if(item.top_code==="brands"){
                                        return (<li>
                                        <label class="container_check">{item.category_name}<small></small>
                                            <input type="checkbox" className="hevent_flt_rightbar hcls_flt_brand" name="hcls_flt_brand" value={item.category_code}/>
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                    }
                                }):''}
                                {/* <li>
                                    <label class="container_check">Adidas <small>11</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Nike <small>08</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Vans <small>05</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Puma <small>18</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- /filter_type --> */}
                    
                </div>
            </aside>

            // {/* <!--end mobile view filter button --> as of now place below code to applier compoent */}

            // <div class="mobile-filter-button">
            //     <a href="#0" class="btn btn-warning open_filters filter-button">
            //         <i class="fa-sharp fa-solid fa-filter my-float"></i>
            //     </a>
            // </div>
            
            // {/* <!--end mobile view filter button --> */}
//         </div>
//     </div>
// </section>
)
}
export default Search_Filters;